import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import ReCAPTCHA from 'react-google-recaptcha'

import Layout from '../components/Layout'

import Spacing from '../components/partials/Spacing'
import { StaticImage } from 'gatsby-plugin-image'

import grafana from '../resources/images/partners/grafana.svg'
import influx_db from '../resources/images/partners/influxdb.svg'
import pagerduty from '../resources/images/partners/pagerduty.svg'
import redis from '../resources/images/partners/redis.svg'

import grid_dynamics from '../resources/images/partners/grid_dynamics.svg'
import emerio from '../resources/images/partners/emerio.svg'
import octamis from '../resources/images/partners/octamis.svg'
import okta from '../resources/images/partners/okta.svg'
import summit from '../resources/images/partners/summit.svg'

import ZoominfoFields from '../components/partials/Zoominfo-fields'
import callZoominfo from '../helpers/zoomInfo'
import { getClientId } from '../helpers/getClientId'
import { getCookieByName } from '../helpers/getCookieByName'

const partners = () => {
  const data = useStaticQuery(graphql`
    query PartnersSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Partners" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    // Zoominfo Snippet
    callZoominfo('qeaakHWCTtKYuT98MCW4')

    // Getting clientId
    const interval = setInterval(function () {
      if (getClientId()) {
        let gaClientId = getClientId()
        document.querySelector<HTMLInputElement>('#ga_client_id').value =
          gaClientId
        clearInterval(interval)
      }
    }, 1000)
  }, [])

  const onChange = () => {
    document.querySelector<HTMLInputElement>('#formsubmit').disabled = false
  }

  const Scroll = () => {
    document
      .querySelector('#partnerSection')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const onClick = () => {
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
  }

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="text-center">
              <h1>Partner Ecosystem</h1>
              <h2 className="hero-description-dark pr-3 pt-4 roboto">
                In an open source world, we are our network
              </h2>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center">
            <h2 className="roboto pb-1"> Cloud</h2>
            <p className="roboto mb-5 mobile-padding-left mobile-padding-right">
              Lenses.io runs natively on Amazon Web Services (AWS), Microsoft
              Azure, and Google Cloud Platform (GCP), available on Marketplaces
              and easily deployable in the Cloud in just a few steps.
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-md-3 col-sm-3 col-10 partners-box  mx-3 text-center bg-white">
              <div className="w-50 partner-logo-image pt-5 mx-auto">
                <StaticImage
                  src="../resources/images/wizard_form/aws.png"
                  placeholder="tracedSVG"
                  alt="AWS"
                />
              </div>
              <p className="partner-text cera-pro f-16">
                Observe data pipelines on AWS MSK
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="/cloud/aws-msk/">
                    More on AWS <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <div className="w-50 partner-logo-image pt-5 mx-auto">
                <StaticImage
                  src="../resources/images/wizard_form/azure.png"
                  placeholder="tracedSVG"
                  alt="Azure"
                />
              </div>
              <p className="partner-text cera-pro f-16">
                Analyze your data pipelines on Azure HDInsight
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="/cloud/azure/">
                    More on Azure HDInsight{' '}
                    <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <div className="w-50 pt-5 partner-logo-image ipad-padding-reset mx-auto">
                <StaticImage
                  src="../resources/images/logos-clouds/google-cloud.svg"
                  placeholder="tracedSVG"
                  alt="Google Cloud"
                />
              </div>
              <p className="partner-text cera-pro f-16">
                Deploy on Google Cloud infrastructure
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="/cloud/google-cloud/">
                    More on Google Cloud <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap pt-4 justify-content-center">
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <div className="w-50 pt-5 partner-logo-image mx-auto">
                <StaticImage
                  src="../resources/images/cloud/ibm_cloud.svg"
                  placeholder="tracedSVG"
                  alt="IBM"
                />
              </div>
              <p className="partner-text cera-pro f-16">
                See into your IBM Event Streams
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a
                    className="primary-color"
                    href="//docs.lenses.io/install_setup/deployment-options/ibm-deployment.html"
                  >
                    More on IBM Cloud <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <div className="pt-5 partner-logo-image mx-auto">
                <StaticImage
                  className="mt-4"
                  src="../resources/images/cloud/aiven_horizontal.svg"
                  placeholder="tracedSVG"
                  alt="Aiven"
                />
              </div>
              <p className="partner-text cera-pro f-16">
                Leverage your Managed Kafka with Aiven
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a
                    className="primary-color"
                    href="//docs.lenses.io/4.3/installation/cloud/aiven/"
                  >
                    More on Aiven <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <div className="pt-5 partner-logo-image mx-auto">
                <StaticImage
                  src="../resources/images/homepage/logos/confluent_cloud_apache.png"
                  placeholder="tracedSVG"
                  alt="Confluent cloud"
                />
              </div>
              <p className="partner-text cera-pro f-16">
                Managed service by the original creators of Kafka
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a
                    className="primary-color"
                    href="https://help.lenses.io/quickstart/managed-kafka/confluent-cloud/"
                  >
                    More on Confluent Cloud{' '}
                    <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap pt-4 justify-content-center">
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <div className="pt-5 partner-logo-image mx-auto">
                <StaticImage
                  className="mt-4"
                  src="../resources/images/partners/instaclustr.svg"
                  placeholder="tracedSVG"
                  alt="Instaclustr"
                />
              </div>
              <p className="partner-text cera-pro f-16">
                Managed Kafka with Instaclustr
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a
                    className="primary-color"
                    href="//www.instaclustr.com/solutions/managed-apache-kafka/"
                  >
                    More on Instaclustr <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center mb-3">
            <h2 className="roboto pb-1"> Technology</h2>
            <p className="roboto mb-5">
              Lenses.io leverages the best-in-class technology platforms to
              provide an integrated end-to-end data experience.
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-md-3 col-sm-3 col-10 partners-box  mx-3 text-center bg-white">
              <img
                className="partner-logo-image pt-5 mx-auto"
                src={grafana}
                alt="Grafana"
              />

              <p className="partner-text cera-pro f-16">
                The best Grafana dashboard for Kafka metrics
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="//grafana.com/">
                    More on Grafana <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <img
                className="partner-logo-image pt-5 mx-auto"
                src={influx_db}
                alt="InfluxDB"
              />
              <p className="partner-text cera-pro f-16">
                Leverage Lenses over this time series database
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="//www.influxdata.com/">
                    More on InfluxDB <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <img
                className="w-50 pt-5 partner-logo-image"
                src={redis}
                alt="Redis"
              />
              <p className="partner-text cera-pro f-16">
                Use Lenses SQL over Redis data & streams
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="/kafka-connectors/kafka-to-redis/">
                    More on Redis <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap pt-4 justify-content-center">
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <img
                className="w-50 pt-5 partner-logo-image"
                src={pagerduty}
                alt="PagerDuty"
              />
              <p className="partner-text cera-pro f-16">
                Configure & send your systems&apos; notifications & alerts
              </p>

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="//www.pagerduty.com/">
                    More on Pagerduty <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box  mx-3 text-center bg-white">
              <img
                className="w-50 pt-5 partner-logo-image"
                src={okta}
                alt="okta"
              />
              <p className="partner-text cera-pro f-16">
                Use Lenses with correct access controls & namespaces
              </p>
              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a
                    className="primary-color"
                    href="//www.okta.com/integrations/lenses-io/"
                  >
                    More on Okta <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top px-0 mx-3 ">
              <a className="cursor-pointer" onClick={Scroll}>
                <StaticImage
                  className="w-100 h-100 zoom-on-hover-1 d-none d-sm-block shadow-sm rounded-1"
                  src="../resources/images/partners/beNextSmall.jpg"
                  placeholder="tracedSVG"
                  alt="Be next"
                />
                <StaticImage
                  className="w-100 h-100 zoom-on-hover-1 d-sm-none d-block shadow-sm rounded-1"
                  src="../resources/images/partners/beNextLarge.jpg"
                  placeholder="tracedSVG"
                  alt="Be next"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center mb-3">
            <h2 className="roboto pb-1"> Consulting</h2>
            <p className="roboto mb-5">
              Our global and local partners can help you accelerate and scale
              your project delivery through their experience and innovation
              channels.
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-md-3 col-sm-3 col-10 partners-box text-center  mx-3">
              <img
                className="w-50 partner-logo-margin partner-logo-image"
                src={emerio}
                alt="Emerio"
              />

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="//www.emeriocorp.com/">
                    More on Emerio <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box text-center  mx-3">
              <img
                className="w-50 partner-logo-margin partner-logo-image"
                src={grid_dynamics}
                alt="Grid Dynamics"
              />

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="//www.griddynamics.com/">
                    More on Grid Dynamics <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box text-center  mx-3">
              <img
                className="w-50 partner-logo-margin partner-logo-image"
                src={summit}
                alt="Summit BRA"
              />

              <div className=" d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="//www.summit-bra.com/">
                    More on Summit BRA <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap pt-4 justify-content-center">
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top partners-box text-center mx-3">
              <img
                className="w-50 partner-logo-margin partner-logo-image"
                src={octamis}
                alt="Octamis"
              />

              <div className="d-flex align-items-center justify-content-center">
                <div className="learn-more-partners align-self-center">
                  <a className="primary-color" href="//www.octamis.com/">
                    More on Octamis <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-10 mobile-margin-top px-0 mx-3 ">
              <a className="cursor-pointer" onClick={Scroll}>
                <StaticImage
                  className="w-100 h-100 zoom-on-hover-1 d-none d-sm-block shadow-sm rounded-1"
                  src="../resources/images/partners/beNextSmall.jpg"
                  placeholder="tracedSVG"
                  alt="Be next"
                />
                <StaticImage
                  className="w-100 h-100 zoom-on-hover-1 d-sm-none d-block shadow-sm rounded-1"
                  src="../resources/images/partners/beNextLarge.jpg"
                  placeholder="tracedSVG"
                  alt="Be next"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <div id="partnerSection"></div>
      <Spacing />

      <section>
        <div className="container-1 bg-dark rounded-1 mobile-margin-top mobile-radius-reset ipad-radius-reset">
          <div className="d-flex flex-wrap justify-content-center mobile-padding-left mobile-padding-right">
            <div className="py-5 ">
              <div className="text-center">
                <h2 className="text-white mb-3 pb-0">
                  Interested in partnering with us?
                </h2>
              </div>
              <div className="d-flex flex-wrap">
                {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                <form
                  action="https://go.pardot.com/l/877242/2020-07-31/4fjsh5"
                  method="POST"
                  id="zoominfo-default-form"
                  onSubmit={onClick}
                >
                  <ZoominfoFields />
                  <input
                    className="m-2 form-control p-4 rounded25 form-border mobile-margin-reset"
                    type="text"
                    required
                    maxLength={40}
                    name="First Name"
                    placeholder="First Name*"
                  />
                  <div className="mobile-padding-top-half"></div>
                  <input
                    className="m-2 form-control p-4 rounded25 form-border mobile-margin-reset"
                    type="text"
                    required
                    maxLength={80}
                    name="Last Name"
                    placeholder="Last Name*"
                  />
                  <div className="mobile-padding-top-half"></div>
                  <input
                    className="m-2 form-control p-4 form-border rounded25 mobile-margin-reset"
                    id="email"
                    type="email"
                    required
                    maxLength={100}
                    name="Email"
                    placeholder="Email*"
                  />
                  <div className="mobile-padding-top-half"></div>
                  <input
                    className="m-2 form-control p-4 form-border rounded25 mobile-margin-reset"
                    type="text"
                    required
                    maxLength={100}
                    name="Company"
                    placeholder="Company*"
                  />
                  <div className="mobile-padding-top-half"></div>
                  <input
                    className="m-2 form-control p-4 form-border rounded25 mobile-margin-reset"
                    type="text"
                    required
                    maxLength={100}
                    name="Title"
                    placeholder="Job Title*"
                  />
                  <div className="mobile-padding-top-half"></div>
                  <div className="input-group m-2 mobile-margin-reset">
                    <select
                      className="my-1 bg-white  rounded25 pl-4 countries-dropdown"
                      name="Country Code"
                    >
                      <option value="">Country</option>
                      <option value="AFG&#x20;&#x2b;93">AFG +93</option>
                      <option value="ALB&#x20;&#x2b;355">ALB +355</option>
                      <option value="ARG&#x20;&#x2b;54">ARG +54</option>
                      <option value="AUS&#x20;&#x2b;61">AUS +61</option>
                      <option value="AUT&#x20;&#x2b;43">AUT +43</option>
                      <option value="BEL&#x20;&#x2b;32">BEL +32</option>
                      <option value="BIH&#x20;&#x2b;387">BIH +387</option>
                      <option value="BRA&#x20;&#x2b;55">BRA +55</option>
                      <option value="BG&#x20;&#x2b;359">BGR +359</option>
                      <option value="CA&#x20;&#x2b;1">CAN +1</option>
                      <option value="CH&#x20;&#x2b;41">CHE +41</option>
                      <option value="CHL&#x20;&#x2b;56">CHL +56</option>
                      <option value="CN&#x20;&#x2b;86">CHN +86</option>
                      <option value="CO&#x20;&#x2b;57">COL +57</option>
                      <option value="CR&#x20;&#x2b;506">CRI +506</option>
                      <option value="CY&#x20;&#x2b;357">CYP +357</option>
                      <option value="CZ&#x20;&#x2b;420">CZE +420</option>
                      <option value="DE&#x20;&#x2b;49">DEU +49</option>
                      <option value="DK&#x20;&#x2b;45">DNK +45</option>
                      <option value="EG&#x20;&#x2b;20">EGY +20</option>
                      <option value="ES&#x20;&#x2b;34">ESP +34</option>
                      <option value="EE&#x20;&#x2b;372">EST +372</option>
                      <option value="FI&#x20;&#x2b;358">FIN +358</option>
                      <option value="FR&#x20;&#x2b;33">FRA +33</option>
                      <option value="GR&#x20;&#x2b;30">GRC +30</option>
                      <option value="HR&#x20;&#x2b;385">HRV +385</option>
                      <option value="IND&#x20;&#x2b;91">IND +91</option>
                      <option value="IS&#x20;&#x2b;354">ISL +354</option>
                      <option value="IT&#x20;&#x2b;39">ITA +39</option>
                      <option value="JP&#x20;&#x2b;81">JPN +81</option>
                      <option value="NL&#x20;&#x2b;31">NLD +31</option>
                      <option value="NZ&#x20;&#x2b;64">NZL +64</option>
                      <option value="NG&#x20;&#x2b;234">NGA +234</option>
                      <option value="NO&#x20;&#x2b;47">NOR +47</option>
                      <option value="PT&#x20;&#x2b;351">PRT +351</option>
                      <option value="RU&#x20;&#x2b;7">RUS +7</option>
                      <option value="TR&#x20;&#x2b;90">TUR +90</option>
                      <option value="UA&#x20;&#x2b;380">UKR +380</option>
                      <option value="UK&#x20;&#x2b;44">UK +44</option>
                      <option value="US&#x20;&#x2b;1">USA +1</option>
                      <option value="other">Other</option>
                    </select>

                    <input
                      className="my-1 ml-0 form-control input-group-append rounded25  p-4 border-left-0"
                      type="text"
                      maxLength={30}
                      name="Phone"
                      placeholder="Phone Number"
                    />
                  </div>

                  {/* <!-- Phone & country ^ --> */}
                  <div className="mobile-padding-top-half"></div>

                  <select
                    className="m-2 bg-white pl-4 w-100 p-3 partners-dropdown rounded25 mobile-margin-reset"
                    name="Partnership Type"
                    required
                  >
                    <option value="">Choose Partnership Type *</option>
                    <option value="technology">Technology</option>
                    <option value="consulting">Consulting</option>
                    <option value="reseller">Reseller</option>
                  </select>

                  <input
                    name="GA Client ID"
                    type="hidden"
                    className="d-none"
                    id="ga_client_id"
                  />

                  <input
                    type="hidden"
                    name="Splunk ID"
                    id="splunk_id"
                    className="d-none"
                  />

                  <div className="mobile-padding-top-half"></div>
                  <textarea
                    id="textarea"
                    className="m-2 form-control p-3 rounded25 mobile-margin-reset"
                    name="Partner Message"
                    maxLength={2000}
                    rows={1}
                    placeholder="Tell us more about why you’re reaching out*"
                    required
                  />
                  <div className="text-white m-2 mobile-padding-top-half">
                    <label className="d-none" htmlFor="Terms Of Use">
                      {' '}
                      Terms of use and Privacy Policy
                    </label>
                    <input type="checkbox" name="Terms Of Use" required />{' '}
                    <span className="text-white pl-1">
                      By submitting the form, you agree to our{' '}
                      <a
                        className="text-white link-text"
                        href="/legals/terms-and-conditions/"
                      >
                        Terms of Use{' '}
                      </a>
                      and{' '}
                      <a
                        className="text-white link-text"
                        href="/legals/policy/"
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </div>
                  <ReCAPTCHA
                    className="ml-2 "
                    sitekey="6LfNhSEUAAAAAL9OJM7RO856GguxnnKDM-uveavH"
                    onChange={onChange}
                  />
                  <div>
                    <div className="mobile-padding-top-half"></div>
                    <input
                      className="ml-2 my-3 border-0 py-2 bg-red text-white f-16 fw-600 w-100 rounded25 opct-10 mobile-margin-reset"
                      id="formsubmit"
                      type="submit"
                      value="Send now"
                      disabled
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
    </Layout>
  )
}

export default partners
